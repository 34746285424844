import { fileExtArr } from './fileformat';

const Links = {
  bindEvents: () => {
    //  add classes to links
    // exclude certain elements
    const elementalLinks = document
      .getElementById('elemental-area')
      .getElementsByTagName('a');
    const hardcodedLinks = document.querySelectorAll('.--has-link-icons a');
    const links = [...elementalLinks, ...hardcodedLinks];

    for (const link of links) {
      const href = link.getAttribute('href');
      const ext = href ? href.slice(-3) : '';

      if (
        !link.classList.contains('btn')
        && !link.classList.contains('no-link-style')
        && !link.classList.contains('link--icon')
        && !link.classList.contains('link--content')
      ) {
        href && link.classList.add('link');
        if (link.getAttribute('target') === '_blank' || link.outerHTML.includes('http')) {
          link.classList.add('external-link');
        }
        if (fileExtArr.includes(ext)) {
          link.classList.add('download-link');
        }
      }
    }

    const boxes = document.querySelectorAll('.elementrelatedlinks');

    boxes.forEach((box) => {
      const relatedLinks = box.getElementsByTagName('a');
      for (const link of relatedLinks) {
        const href = link.getAttribute('href');

        if (
          !link.classList.contains('btn') &&
          !link.classList.contains('no-link-style') &&
          !link.classList.contains('external-link') &&
          !link.classList.contains('download-link')
        ) {
          href && link.classList.add('related-link');
        }
      }
    });
  },
};

export default Links;
