import Cookies from 'js-cookie';
import { fileExtArr } from '../Links/fileformat';

const SiteAlert = {
  init: () => {
    const alertBanner = document.getElementById('site-alert');
    const search = document.getElementById('search-modal');

    const bannerCookie = Cookies.get('AlertDismissed');
    const header = document.querySelector('header');
    const layout = document.getElementById('layout');
    const initOffset = `${header.clientHeight.toString()}px`;

    const LayoutOffsetTop = () => {
      layout.style.marginTop = `${header.clientHeight.toString()}px`;
    };

    layout.style.marginTop = initOffset;
    if (search) search.style.marginTop = initOffset;

    window.addEventListener('resize', () => {
      LayoutOffsetTop();
    });

    const OffsetTop = () => {
      const alertH = alertBanner.clientHeight;
      const headerH = header.clientHeight;
      const bannerOffset = `${headerH.toString()}px`;
      const layoutOffset = `${(headerH + alertH).toString()}px`;
      alertBanner.style.top = bannerOffset;
      layout.style.marginTop = layoutOffset;
      if (search) {
        search.style.marginTop = layoutOffset;
      }
    };

    if (alertBanner) {
      // add link class
      const alertAttribute = alertBanner.hasAttribute('data-alert');
      const links = alertBanner.getElementsByTagName('a');
      OffsetTop();
      window.addEventListener('resize', () => {
        OffsetTop();
      });
      for (const link of links) {
        const href = link.getAttribute('href');
        const ext = href ? href.slice(-3) : '';

        if (
          !link.classList.contains('btn') &&
          !link.classList.contains('no-link-style')
        ) {
          href && link.classList.add('link');
          if (link.getAttribute('target') === '_blank') {
            link.classList.add('external-link');
          }
          if (fileExtArr.includes(ext)) {
            link.classList.add('download-link');
          }
          if (alertAttribute) {
            link.classList.add('reversed-colour');
          }
        }
      }

      if (bannerCookie) {
        // display the alert banner even if it has been dismissed
        if (alertAttribute) {
          OffsetTop();
        } else {
          alertBanner.classList.add('hidden');
          alertBanner.setAttribute('aria-hidden', true);
          const bannerOffset = `${header.clientHeight.toString()}px`;
          layout.style.marginTop = bannerOffset;
          if (search) {
            search.style.marginTop = bannerOffset;
          }
        }
      }
      if (document.querySelector('.dismiss-alert')) {
        const closeBtn = document.querySelector('.dismiss-alert');
        closeBtn.addEventListener('click', () => {
          Cookies.set('AlertDismissed', 'true');
          alertBanner.setAttribute('aria-hidden', true);
          alertBanner.classList.add('hidden');
          const bannerOffset = `${header.clientHeight.toString()}px`;
          layout.style.marginTop = bannerOffset;
          if (search) {
            search.style.marginTop = bannerOffset;
          }
        });
      }
    }
  },
};

export default SiteAlert;
