const Header = {
  bindEvents: () => {
    // dropdown submenu with transition
    document.querySelectorAll('.dropdown-submenu').forEach((menu) => {
      menu.addEventListener('click', () => {
        // hide all other submenus
        document
          .querySelectorAll('.dropdown-submenu__menu')
          .forEach((submenus) => {
            submenus.classList.remove('show');
          });
        // add show to the correct submenu
        menu.querySelector('.dropdown-submenu__menu').classList.add('show');
      });
    });
  },
};

export default Header;
