/* eslint-disable no-param-reassign */
import gsap from 'gsap';

const FlyoutNav = {
  navWrapper: document.querySelector('.flyout-nav'),
  navBg: null,
  triggerOpenButton: document.querySelector('.flyout-nav__trigger'),
  triggerCloseButtons: document.querySelectorAll('.flyout-nav__header--close'),
  triggerBackButtons: document.querySelectorAll('.flyout-nav__header--back'),
  firstLevel: document.querySelector('.flyout-nav__layer--first'),
  allLinks: document.querySelectorAll('.flyout-nav__link'),
  isOpen: false,
  showStagger: window.innerWidth > 549,
  activeLevel: 0,
  activeLayer: null,
  focusedLink: null,
  padding: 0,
  init() {
    if (!FlyoutNav.navWrapper) return;

    // Get the padding applied via CSS, we use this to offset the layers. Add a default incase it returns null
    FlyoutNav.padding =
      // eslint-disable-next-line radix
      parseInt(
        getComputedStyle(FlyoutNav.navWrapper).getPropertyValue(
          '--flyout-padding'
        )
      ) || 40;

    // Default ease and speed
    gsap.defaults({
      duration: 0.25,
      ease: 'power2.out',
    });

    // Add the background element
    FlyoutNav.createBackground();

    // Register the needed events
    FlyoutNav.events();
  },
  createBackground() {
    const background = document.createElement('div');
    background.classList.add('flyout-nav__background');

    FlyoutNav.navWrapper.prepend(background);
    FlyoutNav.navBg = background;
  },
  events() {
    // Open menu on click of trigger
    FlyoutNav.triggerOpenButton.addEventListener('click', () => {
      FlyoutNav.openFlyout();
    });

    // Open menu on ArrowDown or Enter and focus first item
    FlyoutNav.triggerOpenButton.addEventListener('keydown', (e) => {
      e.preventDefault();

      if (e.code === 'ArrowDown' || e.code === 'Enter') {
        if (!FlyoutNav.isOpen) FlyoutNav.openFlyout();
      }
    });

    // Close flyout when clicking/entering close button
    FlyoutNav.triggerCloseButtons.forEach((el) => {
      el.addEventListener('click', () => {
        FlyoutNav.closeFlyout();
      });
      el.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
          FlyoutNav.closeFlyout();
        }
      });
    });

    // Close layer when clicking/entering back button
    FlyoutNav.triggerBackButtons.forEach((el) => {
      const layer = el.closest('ul');

      el.addEventListener('click', () => {
        FlyoutNav.closeLayer(layer);
      });

      el.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
          FlyoutNav.closeLayer(layer);
        }
      });
    });

    // Close on click of background overlay
    FlyoutNav.navBg.addEventListener('click', () => {
      FlyoutNav.closeFlyout();
    });

    // Navigate through the menus
    FlyoutNav.allLinks.forEach((link) => {
      if (link.getAttribute('aria-haspopup') === 'true') {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          FlyoutNav.openLayer(link);
        });
      }
    });

    document.addEventListener('keydown', (e) => {
      if (!FlyoutNav.isOpen) return;

      // Close menu on escape
      if (e.code === 'Escape' && FlyoutNav.isOpen) {
        FlyoutNav.closeFlyout();
      }

      // Arrow down to the next/first link
      if (e.code === 'ArrowDown' && FlyoutNav.focusedLink) {
        e.preventDefault();
        const next = FlyoutNav.getNextFocus(FlyoutNav.focusedLink);
        FlyoutNav.setFocus(next);
      }

      // Arrow up to the previous/last link
      if (e.code === 'ArrowUp' && FlyoutNav.focusedLink) {
        e.preventDefault();
        const prev = FlyoutNav.getPreviousFocus(FlyoutNav.focusedLink);
        FlyoutNav.setFocus(prev);
      }

      // Arrow right to the next menu
      if (e.code === 'ArrowRight') {
        if (FlyoutNav.focusedLink.getAttribute('aria-haspopup') === 'true') {
          FlyoutNav.openLayer(FlyoutNav.focusedLink);
        }
      }

      // Arrow left to the previous menu on Back buttons only
      if (
        e.code === 'ArrowLeft' &&
        FlyoutNav.focusedLink.classList.contains('flyout-nav__header--back')
      ) {
        FlyoutNav.closeLayer(FlyoutNav.focusedLink.closest('ul'));
      }
    });
  },
  openFlyout() {
    FlyoutNav.isOpen = true;
    FlyoutNav.navWrapper.classList.add('flyout-nav--open');
    FlyoutNav.updateAria(FlyoutNav.triggerOpenButton);
    FlyoutNav.firstLevel.hidden = false;

    gsap.set(FlyoutNav.firstLevel, {
      display: 'block',
      opacity: 0,
      x: '100%',
    });

    gsap.to(FlyoutNav.firstLevel, {
      opacity: 1,
      onComplete: () => {
        if (!FlyoutNav.focusedLink) {
          FlyoutNav.setFocus(
            FlyoutNav.firstLevel.querySelector('.flyout-nav__link')
          );
        } else {
          FlyoutNav.setFocus(FlyoutNav.focusedLink);
        }
      },
    });

    FlyoutNav.staggerFirstLevel();
  },
  closeFlyout() {
    FlyoutNav.isOpen = false;
    FlyoutNav.navWrapper.classList.remove('flyout-nav--open');
    FlyoutNav.updateAria(FlyoutNav.triggerOpenButton, false);

    gsap.to(FlyoutNav.firstLevel, {
      opacity: 0,
      x: '100%',
      clearProps: 'all',
      onComplete: () => {
        FlyoutNav.firstLevel.hidden = true;
      },
    });
  },
  openLayer(link) {
    const layer = link.nextElementSibling;
    const prevLayer = link.closest('ul');
    link.classList.add('flyout-nav__link--open');
    FlyoutNav.updateAria(link);
    layer.hidden = false;

    if (FlyoutNav.showStagger) FlyoutNav.activeLevel += 1;

    gsap.set(layer, {
      display: 'block',
      // opacity: 0,
      x: '100%',
    });

    gsap.to(layer, {
      // opacity: 1,
      x: FlyoutNav.showStagger ? FlyoutNav.padding : 0,
      onComplete: () => {
        // setTimeout(() => {
        layer.classList.add('flyout-nav__layer--open');
        // }, 500);
      },
    });

    if (FlyoutNav.showStagger) {
      FlyoutNav.staggerLinks(prevLayer);
      FlyoutNav.staggerFirstLevel();
    }

    FlyoutNav.setFocus(layer.querySelector('.flyout-nav__link'));
  },
  closeLayer(layer) {
    layer.classList.remove('flyout-nav__layer--open');
    layer.previousElementSibling.classList.remove('flyout-nav__link--open');
    FlyoutNav.updateAria(layer.previousElementSibling, false);

    if (FlyoutNav.showStagger) FlyoutNav.activeLevel -= 1;

    gsap.to(layer, {
      // opacity: 0,
      x: '100%',
      clearProps: 'all',
      onComplete: () => {
        // layer.removeAttribute('style');
        layer.hidden = true;
      },
    });

    if (FlyoutNav.showStagger) {
      FlyoutNav.staggerLinks(layer.previousElementSibling.closest('ul'), true);
      FlyoutNav.staggerFirstLevel();
    }

    FlyoutNav.setFocus(layer.closest('li').querySelector('.flyout-nav__link'));
  },
  staggerFirstLevel() {
    gsap.to(FlyoutNav.firstLevel, {
      x: FlyoutNav.padding * FlyoutNav.activeLevel * -1,
    });
  },
  staggerLinks(layer, reset = false) {
    gsap.to(
      layer.querySelectorAll(':scope > li > div button, :scope > li > a'),
      {
        x: reset ? 0 : (FlyoutNav.padding / 2) * -1,
        onBegin: () => {
          if (!reset) layer.classList.add('flyout-nav__layer--hidden');
        },
        onComplete: () => {
          if (reset) layer.classList.remove('flyout-nav__layer--hidden');
        },
      }
    );
  },
  updateAria(element, state = true) {
    element.setAttribute('aria-expanded', state);
  },
  setFocus(element) {
    FlyoutNav.allLinks.forEach((el) => {
      el.tabIndex = -1;
    });

    element.focus();
    element.tabIndex = 0;
    FlyoutNav.focusedLink = element;
  },
  getPreviousFocus(current) {
    const closestElement = current.closest('li');
    const closestLayer = current.closest('ul');
    const previousElement = !closestElement.previousElementSibling
      ? closestLayer.querySelector(':scope > li:last-child')
      : closestElement.previousElementSibling;
    let previousLink;

    // If the next element is a header, go to the button
    if (
      current.previousElementSibling &&
      current.previousElementSibling.classList.contains('flyout-nav__header')
    ) {
      previousLink = current.previousElementSibling.querySelector('button');
    } else {
      previousLink = previousElement.querySelector(':scope > a')
        ? previousElement.querySelector(':scope > a')
        : previousElement.querySelector('button');
    }

    return previousLink;
  },
  getNextFocus(current) {
    const closestElement = current.closest('li');
    const closestLayer = current.closest('ul');
    const nextElement = !closestElement.nextElementSibling
      ? closestLayer.querySelector(':scope > li:first-child')
      : closestElement.nextElementSibling;
    let nextLink;

    // if the current element is a button, check if there is a 'title' link
    if (
      current.tagName === 'BUTTON' &&
      closestElement.querySelector(':scope > a')
    ) {
      nextLink = closestElement.querySelector(':scope > a');
    } else if (nextElement.querySelector(':scope > .flyout-nav__header')) {
      nextLink = nextElement.querySelector(
        ':scope > .flyout-nav__header > button:first-child'
      );
    } else {
      nextLink = nextElement.querySelector(':scope > a');
    }

    return nextLink;
  },
};

export default FlyoutNav;
