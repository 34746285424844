const ContactForm = {
  init: () => {
    // const form = document.getElementById('ContactForm');
    // const formData = new FormData(form);
    // const submitBtn = document.querySelector('input[type=submit]');
    // const url = form.action + 'doSubmit';
    const successModal = document.querySelector('.form-modal');
    const closeModalBtn = document.querySelector('.close-modal');
    const resetUrl = window.location.origin + window.location.pathname;

    // submitBtn.addEventListener('click', (e) => {
    //   //   e.preventDefault();
    // });

    if (closeModalBtn) {
      closeModalBtn.addEventListener('click', () => {
        successModal.classList.add('hidden');
        successModal.setAttribute('aria-hidden', 'true');
        window.history.pushState({}, document.title, resetUrl);
      });
    }
  },
};

export default ContactForm;
