const Breadcrumbs = {
  init: () => {
    const breadcrumbs = document.querySelector('.breadcrumbs');

    function bindEvents() {
      const tooltipBtns = document.querySelectorAll('.breadcrumbs__link');
      const dropdownBtn = document.querySelector('.breadcrumb-dropdown-btn');
      const dropdown = document.querySelector('.breadcrumb-dropdown');

      tooltipBtns.forEach((btn) => {
        const tooltipHolder = btn.querySelector('.tooltip-holder');

        btn.addEventListener('mouseover', (e) => {
          btn.querySelectorAll('span').forEach((span) => {
            const btnLabel = span.textContent;
            if (
              window.getComputedStyle(span).display !== 'none' &&
              window.getComputedStyle(span).position !== 'absolute' &&
              btnLabel.includes('...')
            ) {
              e.preventDefault();
              tooltipHolder && tooltipHolder.classList.toggle('hidden');
            }
          });
        });

        btn.addEventListener('mouseout', (e) => {
          if (tooltipHolder) {
            (!tooltipHolder.classList.contains('hidden') && !e.target.classList.contains('show-tooltip')) && tooltipHolder.classList.add('hidden');
          }

          if (document.querySelector('.breadcrumb-dropdown')) {
            if (
              !dropdown.classList.contains('hidden') &&
              e.target.nodeName != 'rect'
            ) {
              dropdown.classList.add('hidden');
            }
          }
        });
      });

      if (document.querySelector('.breadcrumb-dropdown-btn')) {
        dropdownBtn.addEventListener('click', (e) => {
          e.preventDefault();
          if (document.querySelector('.breadcrumb-dropdown')) {
            dropdown.classList.toggle('hidden');
          }
        });
      }
    }

    if (breadcrumbs) {
      bindEvents();

      window.addEventListener('resize', () => {
        bindEvents();
      });
    }
  },
};

export default Breadcrumbs;
