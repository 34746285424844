const Table = {
  init: () => {
    if (
      document.getElementById('elemental-area')
      && document.getElementById('elemental-area').getElementsByTagName('table')
    ) {
      const tables = document
        .getElementById('elemental-area')
        .getElementsByTagName('table');
      const paddingX = 32;
      for (const table of tables) {
        /* create container div */
        const tableContainer = document.createElement('div');
        tableContainer.classList.add('table-container');
        tableContainer.setAttribute('id', 'table-container');

        /* create parent div */
        const wrapper = document.createElement('div');
        wrapper.classList.add('table-scroll-wrapper');
        wrapper.setAttribute('id', 'table-scroll-wrapper');

        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);

        wrapper.parentNode.insertBefore(tableContainer, wrapper);
        tableContainer.appendChild(wrapper);

        /* create icons container */
        const flexContainer = document.createElement('div');
        flexContainer.classList.add('flex', 'md:hidden');

        /* create left icon */
        const scrollOverflowLeft = document.createElement('div');
        scrollOverflowLeft.classList.add(
          'scroll-overflow__left',
          'fade-out-effect',
          'opacity-0',
        );
        /* create right icon */
        const scrollOverflowRight = document.createElement('div');
        scrollOverflowRight.classList.add(
          'scroll-overflow__right',
          'fade-out-effect',
          'opacity-0',
        );

        tableContainer.appendChild(flexContainer);
        flexContainer.appendChild(scrollOverflowLeft);
        flexContainer.appendChild(scrollOverflowRight);

        const tableWidth = table.clientWidth;
        const { scrollWidth } = document.getElementById('table-scroll-wrapper');

        if (tableWidth > screen.width - paddingX) {
          if (scrollOverflowRight.classList.contains('opacity-0')) {
            scrollOverflowRight.classList.remove('opacity-0');
          }
        }
        window.addEventListener('resize', () => {
          const tableWidth = table.clientWidth;

          if (tableWidth + paddingX > screen.width) {
            if (scrollOverflowRight.classList.contains('opacity-0')) {
              scrollOverflowRight.classList.remove('opacity-0');
            }
          }
        });

        const boxes = document.querySelectorAll('.table-scroll-wrapper');
        boxes.forEach((box) => {
          box.addEventListener('scroll', () => {
            const { scrollWidth } = box;
            const userScroll = box.scrollLeft;

            const calculation = scrollWidth - screen.width + paddingX;

            /* display the left icon when scroll */
            if (userScroll > 1) {
              scrollOverflowLeft.classList.remove('opacity-0');
            } else {
              scrollOverflowLeft.classList.add('opacity-0');
            }
            /* hide the right arrow when reach the end of right  */
            if (userScroll >= calculation) {
              scrollOverflowRight.classList.add('opacity-0');
            } else if (scrollOverflowRight.classList.contains('opacity-0')) {
              scrollOverflowRight.classList.remove('opacity-0');
            }
          });
        });
      }
    }
  },
};

export default Table;
