import mixitup from 'mixitup';

class ServiceDropdown {
  constructor(element) {
    this.root = element;
    this.targetID = this.root.dataset.target;
    // container of cards to filter
    this.cards = document.getElementById(this.targetID);

    if (this.cards) this.Filter();
  }

  Filter() {
    this.resetClass = '.null-class-to-force-hide-on-init';
    // this.resetClass = '*';
    this.mixer = mixitup(this.cards, {
      selectors: {
        target: '.services__card',
      },
      load: {
        filter: this.resetClass,
      },
      animation: {
        duration: 300,
      },
    });

    // prettier-ignore
    // Custom event listener for the custom dropdown changing value
    this.root.addEventListener('customDropdownChange', () => {
      const selectedValue = this.root.dataset.value;
      // eslint-disable-next-line eqeqeq
      const filterValue = selectedValue == 0 ? this.resetClass : `.services__provider--nationwide, .services__provider--${selectedValue}`;
      this.mixer.filter(filterValue);
    }, false);
  }
}

export default ServiceDropdown;
