const Navigation = {
  toggleMenuButton: document.querySelector('#mobile-nav-toggle'),
  languageSwitcherButtons: document.querySelectorAll(
    '#language-switcher-toggle'
  ),
  searchButton: document.querySelector('#search-toggle'),
  toggleSubMenuButton: document.querySelectorAll('[data-toggle-sub]'),
  toggleBackButton: document.querySelectorAll('[data-toggle-back]'),
  mobileNavContainer: document.querySelector('[data-mobile-nav]'),
  body: document.querySelector('body'),
  menuOpen: false,

  init: () => {
    // Campaign pages use a different nav - check button exists to prevent intialising
    if (Navigation.toggleMenuButton) Navigation.setup(document);
  },

  setup: (scope) => {
    Navigation.attachEvents(scope);
  },

  attachEvents: (scope) => {
    const getFocusableElements = () => {
      const currentLevel =
        Navigation.mobileNavContainer.getAttribute('data-current-level');

      if (currentLevel === '1') {
        const mergedItems = [];
        const levelOneItems = document.querySelectorAll(
          '.mobile-nav__item--l1'
        );

        levelOneItems.forEach((element) => {
          const subelements = element.querySelectorAll('button, [href]');
          subelements.forEach((subelement) => {
            mergedItems.push(subelement);
          });
        });

        return mergedItems;
      }

      return document
        .querySelector('.mobile-nav__list--current')
        .querySelectorAll('button, [href]');
    };

    const setFocus = () => {
      const focusableElements = getFocusableElements();
      focusableElements[0].focus();
      focusableElements[focusableElements.length - 1].addEventListener(
        'focusout',
        () => {
          focusableElements[0].focus();
        }
      );
    };

    const toggleLanguageSwitcher = () => {
      document.querySelectorAll('.language-switcher__list').forEach((list) => {
        list.classList.toggle('hidden');
      });
      document
        .querySelectorAll('.language-switcher__arrow')
        .forEach((arrow) => {
          arrow.classList.toggle('language-switcher__arrow--up');
        });
    };

    // Main Hamburger Button
    Navigation.toggleMenuButton.addEventListener('click', () => {
      Navigation.doToggleMenu();
      setFocus();
    });

    // Navigation through submenus
    Navigation.mobileNavContainer.addEventListener('click', (e) => {
      if (e.target !== e.currentTarget) {
        if (
          e.target.tagName === 'BUTTON' ||
          e.target.tagName === 'H4' ||
          e.target.tagName === 'H5' ||
          e.target.tagName === 'A'
        ) {
          return;
        }
        Navigation.openMenuLevel(e);
        setFocus();
      } else {
        // Click away to close menu
        Navigation.doToggleMenu();
      }
    });

    // Escape key closes menu
    scope.addEventListener('keypress', (e) => {
      if (Navigation.menuOpen && e.key === 'Escape') Navigation.doToggleMenu();
    });

    // Clicking search button closes menu
    Navigation.searchButton.addEventListener('click', () => {
      if (Navigation.menuOpen) Navigation.doToggleMenu();
    });

    // Language Switcher
    Navigation.languageSwitcherButtons.forEach((button) => {
      button.addEventListener('click', toggleLanguageSwitcher);
    });

    // Submenu open buttons
    Navigation.toggleSubMenuButton.forEach((button) => {
      button.addEventListener('click', (e) => {
        Navigation.doOpenSubMenu(e);
        setFocus();
      });
    });

    // Submenu back buttons
    Navigation.toggleBackButton.forEach((button) => {
      button.addEventListener('click', (e) => {
        Navigation.doCloseSubMenu(e);
        setFocus();
      });
    });
  },

  doToggleMenu: () => {
    Navigation.mobileNavContainer.classList.add('mobile-nav--animated');
    if (Navigation.menuOpen) {
      Navigation.openMenuLevel({
        target: document.querySelector('.mobile-nav__footer--level1'),
      });
    }

    Navigation.mobileNavContainer.classList.toggle('mobile-nav--open');
    Navigation.body.classList.toggle('scroll-lock');

    if (!Navigation.menuOpen) {
      Navigation.mobileNavContainer
        .querySelectorAll('[data-menu-list]')[0]
        .classList.add('mobile-nav__list--current');
    }

    Navigation.menuOpen = !Navigation.menuOpen;

    if (!Navigation.menuOpen) {
      Navigation.toggleMenuButton.classList.remove('is-active');
      if (
        document
          .getElementById('popout-modal')
          .classList.contains('popout-open')
      ) {
        document.getElementById('popout-modal').classList.remove('hidden');
        document.getElementById('popout-modal').classList.add('flex');
      }
    } else {
      Navigation.toggleMenuButton.classList.add('is-active');
      if (
        document
          .getElementById('popout-modal')
          .classList.contains('popout-open')
      ) {
        document.getElementById('popout-modal').classList.add('hidden');
      }
    }

    Navigation.toggleMenuButton.setAttribute(
      'aria-expanded',
      Navigation.menuOpen
    );
  },

  doOpenSubMenu: (e) => {
    const currentLists = document.querySelectorAll(
      '.mobile-nav__list--current'
    );

    currentLists.forEach((currentList) => {
      currentList.classList.remove('mobile-nav__list--current');
    });

    e.currentTarget.parentNode
      .querySelector('[data-menu-list]')
      .classList.add('mobile-nav__list--open', 'mobile-nav__list--current');
    e.currentTarget.setAttribute('aria-expanded', true);

    const currentLevel =
      Navigation.mobileNavContainer.getAttribute('data-current-level');

    Navigation.mobileNavContainer.setAttribute(
      'data-current-level',
      Number(currentLevel) + 1
    );

    if (Navigation.mobileNavContainer.getAttribute('data-current-level') > 2) {
      Navigation.toggleMenuButton.classList.add('reversed');
      Navigation.searchButton.classList.add('reversed');
    }
  },

  doCloseSubMenu: (e) => {
    const targetMenu = document
      .querySelector('.mobile-nav__list--current')
      .parentNode.closest(
        '.mobile-nav__list--open, .mobile-nav__inner [data-menu-list]'
      );

    document
      .querySelector('.mobile-nav__list--current')
      .classList.remove('mobile-nav__list--current');

    targetMenu.classList.add('mobile-nav__list--current');

    targetMenu
      .querySelector('[aria-expanded=true]')
      .setAttribute('aria-expanded', false);

    e.currentTarget
      .closest('[data-menu-list]')
      .classList.remove('mobile-nav__list--open');

    const currentLevel =
      Navigation.mobileNavContainer.getAttribute('data-current-level');

    Navigation.mobileNavContainer.setAttribute(
      'data-current-level',
      Number(currentLevel) - 1
    );

    if (Navigation.mobileNavContainer.getAttribute('data-current-level') <= 2) {
      Navigation.toggleMenuButton.classList.remove('reversed');
      Navigation.searchButton.classList.remove('reversed');
    }
  },

  openMenuLevel: (e) => {
    const clickTargetSelector = `.${e.target.className.replaceAll(' ', '.')}`;

    const clickTarget = document.querySelector(clickTargetSelector);

    let targetMenu = clickTarget.closest('.mobile-nav__list--nested');

    if (targetMenu == null) {
      targetMenu = clickTarget
        .closest('.mobile-nav__inner')
        .querySelector(':scope > [data-menu-list]');
    }

    if (
      targetMenu &&
      !targetMenu.classList.contains('mobile-nav__list--current')
    ) {
      targetMenu.querySelectorAll('[data-menu-list]').forEach((list) => {
        list.classList.remove(
          'mobile-nav__list--open',
          'mobile-nav__list--current'
        );
      });

      targetMenu.querySelectorAll('[aria-expanded=true]').forEach((list) => {
        list.setAttribute('aria-expanded', false);
      });

      targetMenu.classList.add('mobile-nav__list--current');

      Navigation.mobileNavContainer.setAttribute(
        'data-current-level',
        document.querySelectorAll('.mobile-nav__list--open').length + 1
      );
    }
  },
};

export default Navigation;
