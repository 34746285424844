const PopoutModal = {
  init: () => {
    const queryString = window.location.search;

    function isPopoutFormSubmission() {
      const result = queryString.includes('popout');
      return result;
    }

    if (document.getElementById('popout-modal') || isPopoutFormSubmission()) {
      let top = window.pageYOffset | document.body.scrollTop;
      const popout = document.getElementById('popout-modal');
      let popoutOffset = `-${(popout.clientHeight + 32).toString()}px`;
      popout.style.right = popoutOffset;

      if (popout.classList.contains('invisible')) {
        popout.classList.remove('invisible');
      }

      function newOffsetTop() {
        top = window.pageYOffset | document.body.scrollTop;
      }

      window.onscroll = () => {
        newOffsetTop();
      };

      window.onresize = () => {
        top = window.pageYOffset | document.body.scrollTop;
        popoutOffset = `-${(popout.clientHeight + 32).toString()}px`;
        popout.style.right = popoutOffset;
      };

      const openBtnsDesktop = document.querySelectorAll(
        '#open-popout--desktop'
      );
      const openBtnsMobile = document.querySelectorAll('#open-popout--mobile');

      const formHolder = document.querySelector('.popout-form-holder');

      openBtnsDesktop.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          document.body.classList.toggle('overflow-x-hidden');

          if (popout.classList.contains('popout-closed')) {
            popout.classList.remove('popout-closed');
            popout.classList.add('popout-open');
            popout.classList.remove('rotate-90');
            popout.classList.remove('md:rotate-270');
            popout.classList.remove('lg:rotate-270');
            popout.style.right = '0';
            btn.firstElementChild.classList.remove('hidden');
            formHolder.classList.remove('hidden');
            formHolder.style.height = `${(
              formHolder.clientHeight + 100
            ).toString()}px`;
            popout.style.overflowY = 'scroll';
            formHolder.style.overflowY = 'scroll';
          } else {
            popout.classList.add('popout-closed');
            popout.classList.remove('popout-open');
            if (!btn.classList.contains('mobile-popout')) {
              popout.classList.add('rotate-90');
              popout.classList.add('md:rotate-270');
              popout.classList.add('lg:rotate-270');
            }
            btn.firstElementChild.classList.add('hidden');
            popout.style.right = popoutOffset;
            formHolder.classList.add('hidden');
            popout.style.height = 'auto';
            formHolder.style.height = 'auto';
            popout.style.top = '40%';
          }
        });

        if (isPopoutFormSubmission()) {
          document.querySelector('.page-title')
            ? document.querySelector('.page-title').remove()
            : null;
          popout.classList.remove('popout-closed');
          popout.classList.add('popout-open');
          popout.classList.remove('rotate-90');
          popout.classList.remove('md:rotate-270');
          popout.classList.remove('lg:rotate-270');
          popout.style.right = '0';
          btn.firstElementChild.classList.remove('hidden');
          formHolder.classList.remove('hidden');
          formHolder.style.height = `${(
            formHolder.clientHeight + 60
          ).toString()}px`;
          popout.style.overflowY = 'scroll';
          formHolder.style.overflowY = 'scroll';
        }
      });
      openBtnsMobile.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          document.body.classList.toggle('overflow-x-hidden');

          if (popout.classList.contains('popout-closed')) {
            popout.classList.remove('popout-closed');
            popout.classList.add('popout-open');
            popout.classList.remove('rotate-90');
            popout.classList.remove('md:rotate-270');
            popout.classList.remove('lg:rotate-270');
            popout.style.right = '0';
            btn.firstElementChild.classList.remove('hidden');
            formHolder.classList.remove('hidden');
            formHolder.style.height = `${(
              formHolder.clientHeight + 100
            ).toString()}px`;
            popout.style.overflowY = 'scroll';
            formHolder.style.overflowY = 'scroll';
          } else {
            popout.classList.add('popout-closed');
            popout.classList.remove('popout-open');
            if (!btn.classList.contains('mobile-popout')) {
              popout.classList.add('rotate-90');
              popout.classList.add('md:rotate-270');
              popout.classList.add('lg:rotate-270');
            }
            btn.firstElementChild.classList.add('hidden');
            popout.style.right = popoutOffset;
            formHolder.classList.add('hidden');
            popout.style.height = 'auto';
            formHolder.style.height = 'auto';
            popout.style.top = '40%';
          }
        });

        if (isPopoutFormSubmission()) {
          document.querySelector('.page-title')
            ? document.querySelector('.page-title').remove()
            : null;
          popout.classList.remove('popout-closed');
          popout.classList.add('popout-open');
          popout.classList.remove('rotate-90');
          popout.classList.remove('md:rotate-270');
          popout.classList.remove('lg:rotate-270');
          popout.style.right = '0';
          btn.firstElementChild.classList.remove('hidden');
          formHolder.classList.remove('hidden');
          formHolder.style.height = `${(
            formHolder.clientHeight + 60
          ).toString()}px`;
          popout.style.overflowY = 'scroll';
          formHolder.style.overflowY = 'scroll';
        }
      });
    }

    if (document.querySelector('.close-modal')) {
      const closeModalBtn = document.querySelector('.close-modal');

      if (document.getElementById('enquiry-form')) {
        document.getElementById('enquiry-form').scrollIntoView({
          behavior: 'smooth',
        });
      }

      closeModalBtn.addEventListener('click', () => {
        window.history.back();
      });
    }
  },
};

export default PopoutModal;
