import '@styles/index.scss';

// Import Libraries
import '@imgix/js-core';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Import components
import MicroModal from 'micromodal';
import StoryCarousel from '@components/modules/StoryCarousel';
import Accordion from '@components/modules/Accordion';
import ImageCarousel from '@components/modules/ImageCarousel';
import StaffTile from '@components/modules/StaffTile';
import Header from '@components/modules/Header';
import Links from '@components/modules/Links';
import Media from '@components/modules/Media';
import SiteAlert from '@components/modules/SiteAlert';
import ContactForm from '@components/modules/ContactForm';
import ScrollTo from '@components/modules/ScrollTo';
import Search from '@components/modules/Search';
import PopoutModal from '@components/modules/PopoutModal';
import Navigation from '@components/modules/Navigation';
import Breadcrumbs from '@components/modules/Breadcrumbs';
import Table from '@components/modules/Tables';
import Tabs from '@components/modules/Tabs';
import TealiumLinks from '@components/modules/Tealium';
import IframeVideo from '@components/modules/IframeVideo';
import CustomDropdown from '@components/modules/CustomDropdown';
import ServiceDropdown from '@components/modules/ServiceDropdown';
import FlyoutNav from '@components/modules/FlyoutNav';
import NaturalSearch from '@components/modules/NaturalSearch';
import TableOfContents from '@components/modules/TableOfContents/TableOfContents';
import Pagination from '@components/modules/Pagination/Pagination';

document.addEventListener('DOMContentLoaded', () => {
  // Initialize library components
  StoryCarousel.initCarousel();
  StoryCarousel.mobileCarousel();
  Media.init();
  Accordion.init();
  StaffTile.init();
  ImageCarousel.initCarousel();
  if (document.querySelector('.tablinks')) Tabs.init();

  // Initialize components
  Header.bindEvents();
  if (document.getElementById('elemental-area')) Links.bindEvents();
  document.querySelectorAll('.custom-dropdown').forEach((el) => new CustomDropdown(el));
  document.querySelectorAll('.custom-dropdown__value').forEach((el) => new ServiceDropdown(el));
  document.querySelectorAll('.natural-search').forEach((el) => new NaturalSearch(el));
  document.querySelectorAll('.pagination__link').forEach((el) => new Pagination(el));

  SiteAlert.init();
  ScrollTo.init();
  Navigation.init();
  PopoutModal.init();
  Breadcrumbs.init();
  Search.init();
  Table.init();
  IframeVideo.init();
  TealiumLinks.init();
  FlyoutNav.init();

  TableOfContents.init();

  // Check to see what this is doing
  if (document.getElementById('ContactForm')) ContactForm.init();

  MicroModal.init();
});
