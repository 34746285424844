const IframeVideo = {
  init: () => {
    const iframes = document
      .getElementById('elemental-area')
      ?.getElementsByTagName('iframe');

    Array.from(iframes || [])?.forEach((iframe) => {
      iframe.classList.add('responsive-iframe');
      const iframeContainer = document.createElement('div');
      iframeContainer.classList.add('iframe-container');

      iframe.parentNode.insertBefore(iframeContainer, iframe);
      iframeContainer.appendChild(iframe);
    });
  },
};

export default IframeVideo;
