const ScrollTo = {
  init: () => {
    if (document.querySelector('.scroll-to-form')) {
      const scrollBtn = document.querySelector('.scroll-to-form');

      scrollBtn.addEventListener('click', (e) => {
        e.preventDefault();
        document.getElementById('ContactForm').scrollIntoView({
          behavior: 'smooth',
        });

        const form = document.getElementById('ContactForm');
        const navHeight = document.getElementsByTagName('NAV')[0].clientHeight;

        let offset = navHeight;

        if (document.getElementById('site-alert')) {
          const alertHeight =
            document.getElementById('site-alert').clientHeight;

          offset = -(navHeight + alertHeight + 100);

          form.scrollIntoView(true);
          window.scrollBy(0, offset);
        } else {
          form.scrollIntoView(true);
          window.scrollBy(0, offset);
        }
      });
    }
  },
};

export default ScrollTo;
