const Search = {
  init: () => {
    const form = document.getElementById('global-search-form');
    document.querySelectorAll('.change-field').forEach((fields) => {
      fields.addEventListener('change', () => {
        form.submit();
      });
    });

    // onchange of category clear the select field data
    document.querySelectorAll('.category-field').forEach((fields) => {
      fields.addEventListener('change', () => {
        // clear select fields
        document.querySelectorAll('.category-clear').forEach((field) => {
          field.value = '';
        });

        // submit form
        form.submit();
      });
    });

    // incrementally add pages to div when read more is clicked
    if (document.getElementById('view-more-button')) {
      document
        .getElementById('view-more-button')
        .addEventListener('click', () => {
          const searchResults = document.getElementById('search-results');
          const pageStart = parseInt(
            document.getElementById('page-start').value,
            10
          );
          const pageCounter = parseInt(
            document.getElementById('page-counter').value,
            10
          );
          // add page results count to page start when adding the next lot of results in
          const start = pageStart + pageCounter;
          const path = window.location.href.split('?')[0];

          // add page start to results for next results to be added
          document.getElementById('page-start').value = start;

          // fetch data
          fetch(
            `${path}home/loadMoreResponse${window.location.search}&start=${start}`
          )
            .then((response) => response.text())
            .then((html) => {
              if (html.length == 1) {
                document
                  .getElementById('view-more-button')
                  .classList.add('hidden');
              }
              searchResults.innerHTML += html;
            })
            .catch((err) => {
              console.warn('Something went wrong.', err);
            });
        });
    }

    function trapFocus(element) {
      const focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      );
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const KEYCODE_TAB = 9;
      firstFocusableEl.focus(); // focus on the first element when fired
      element.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      });
    }
    // search modal
    if (document.getElementById('search-toggle')) {
      const toggleSearchBtns = document.querySelectorAll('.search-toggle');
      const searchModal = document.getElementById('search-modal');
      const layout = document.getElementById('layout');

      toggleSearchBtns.forEach((btn) => {
        btn.addEventListener('click', () => {
          layout.classList.toggle('hidden');
          document.getElementsByTagName('body')[0].scrollIntoView({
            behavior: 'smooth',
          });
          btn.classList.toggle('search-closed');
          searchModal.classList.toggle('search-modal-open');
          searchModal.setAttribute(
            'aria-hidden',
            searchModal.getAttribute('aria-hidden') === 'true'
              ? 'false'
              : 'true'
          );

          layout.setAttribute(
            'aria-hidden',
            layout.getAttribute('aria-hidden') === 'false' ? 'true' : 'false'
          );
          trapFocus(searchModal);

          const searchInput = document.getElementById('Keywords');
          if (searchInput) searchInput.focus();
        });
      });
    }
  },
};

export default Search;
