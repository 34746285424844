import gsap from 'gsap';

class CustomDropdown {
  constructor(element) {
    this.root = element;
    this.valueEl = this.root.querySelector('.custom-dropdown__value');
    this.labelEl = this.root.querySelector('.custom-dropdown__label');
    this.dropdownEl = this.root.querySelector('.custom-dropdown__selectbox');
    this.items = this.root.querySelectorAll('.custom-dropdown__item');

    gsap.defaults({
      ease: 'power2.out',
      duration: 0.25,
    });

    this.Events();
  }

  Events() {
    let timeout;

    // Show the dropdown when value element is focused
    this.valueEl.addEventListener('focusin', (e) => {
      this.ShowDropdown();
    });

    // On keyup we need to filter the options based on what is keyed
    this.valueEl.addEventListener('keyup', (e) => {
      clearTimeout(timeout);

      // Ignore arrow keys which have their own listeners
      if (e.code.indexOf('Arrow') > -1) return;

      // prettier-ignore
      // Make sure the label doesn't sit over the top of typed out conten
      if (this.valueEl.innerText === '' && this.valueEl.classList.contains('custom-dropdown__value--has-value')) {
        this.valueEl.classList.remove('custom-dropdown__value--has-value');
      } else if (!this.valueEl.classList.contains('custom-dropdown__value--has-value')) {
        this.valueEl.classList.add('custom-dropdown__value--has-value');
      }

      // Filter out options that don't string match
      timeout = setTimeout(() => {
        // The lowercase string
        const filterString = e.target.innerText.toLowerCase();
        // Count of options, less the reset option
        let counter = this.items.length - 1;

        // Loop over each dropdown option
        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];

          // Ignore the reset item
          if (element.classList.contains('custom-dropdown__item--reset')) {
            continue;
          }

          // Check for string matches
          if (element.innerText.toLowerCase().indexOf(filterString) > -1) {
            // Show matches
            element.hidden = false;
          } else {
            // Hide ones that don't
            element.hidden = true;
            // Decrement the counter
            counter--;
          }
        }

        // prettier-ignore
        // Counter is 0 and there is no alert already
        if (!counter && !this.dropdownEl.querySelector('.custom-dropdown__item--alert')) {
          this.AddAlert();
        }
        // Counter is more than 0 and there is an alert
        else if (counter && this.dropdownEl.querySelector('.custom-dropdown__item--alert')) {
          this.RemoveAlert();
        }
      }, 300);
    });

    // Add events to each option
    this.items.forEach((item) => {
      item.addEventListener('keydown', (e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          this.SetValue(item);
        }
      });
      item.addEventListener('click', (e) => {
        this.SetValue(item);
      });
    });

    document.addEventListener('keydown', (e) => {
      // Go down to the next
      if (e.code === 'ArrowDown' && this.GetDropdownState()) {
        e.preventDefault();
        this.FocusNextOption();
      }
      // Go up to the previous
      if (e.code === 'ArrowUp' && this.GetDropdownState()) {
        e.preventDefault();
        this.FocusPreviousOption();
      }
      // Escape to close
      if (e.code === 'Escape' && this.GetDropdownState()) {
        e.preventDefault();
        this.HideDropdown();
      }
    });

    document.addEventListener('click', (e) => {
      // Close dropdown if it's open and target element is not within the root
      if (this.GetDropdownState() && !this.root.contains(e.target)) {
        this.HideDropdown();
      }
    })
  }

  ShowDropdown() {
    // prettier-ignore
    if (this.dropdownEl.classList.contains('custom-dropdown__selectbox--open')) return;

    this.dropdownEl.classList.add('custom-dropdown__selectbox--open');

    gsap.set(this.dropdownEl, {
      opacity: 0,
      y: 20,
      display: 'block',
    });

    gsap.to(this.dropdownEl, {
      opacity: 1,
      y: 0,
    });
  }

  HideDropdown() {
    gsap.to(this.dropdownEl, {
      opacity: 0,
      y: 20,
      clearProps: 'all',
      onComplete: () => {
        // prettier-ignore
        this.dropdownEl.classList.remove('custom-dropdown__selectbox--open');
        this.UnSetFocus();
        // this.UnSetAria();
      },
    });
  }

  // prettier-ignore
  FocusNextOption() {
    this.focusedItem =
      this.dropdownEl.querySelector('li[aria-selected="true"]') &&
      this.dropdownEl.querySelector('li[aria-selected="true"]')
        .nextElementSibling
        ? this.dropdownEl.querySelector('li[aria-selected="true"]').nextElementSibling
        : this.dropdownEl.querySelector('li:first-child');

    this.SetFocus();
  }

  // prettier-ignore
  FocusPreviousOption() {
    this.focusedItem =
      this.dropdownEl.querySelector('li[aria-selected="true"]') &&
      this.dropdownEl.querySelector('li[aria-selected="true"]')
        .previousElementSibling
        ? this.dropdownEl.querySelector('li[aria-selected="true"]').previousElementSibling
        : this.dropdownEl.querySelector('li:last-child');

    this.SetFocus();
  }

  SetFocus() {
    this.focusedItem.focus();
    this.SetAria();
  }

  UnSetFocus() {
    if (this.focusedItem) {
      this.focusedItem.blur();
      this.focusedItem = null;
    }
    this.UnSetAria();
  }

  SetAria() {
    this.items.forEach((item) => {
      this.UnSetAria(item);
    });
    this.focusedItem.setAttribute('aria-selected', 'true');
    this.focusedItem.tabIndex = 0;
  }

  UnSetAria(item = this.focusedItem) {
    if (item) {
      item.setAttribute('aria-selected', 'false');
      item.tabIndex = -1;
    }
  }

  // prettier-ignore
  GetDropdownState() {
    return !!(this.dropdownEl.classList.contains('custom-dropdown__selectbox--open'));
  }

  // prettier-ignore
  SetValue(item) {
    if (item.classList.contains('custom-dropdown__item--reset')) {
      this.HideDropdown();
      this.valueEl.classList.remove('custom-dropdown__value--has-value');
      this.valueEl.innerText = '';
      this.valueEl.dataset.value = '';
      this.dropdownEl.removeAttribute('aria-activedescendant');
      this.RemoveAlert();
      this.items.forEach((el) => {
        el.hidden = false;
      });
    }
    else {
      const value = item.innerText;
      this.valueEl.innerText = value;
      this.valueEl.dataset.value = item.dataset.value;
      this.valueEl.classList.add('custom-dropdown__value--has-value');
      this.dropdownEl.setAttribute('aria-activedescendant', `custom-dropdown__item--${item.dataset.value}`);
      this.HideDropdown();
    }

    // Setup custom event and trigger it on change
    const event = new Event('customDropdownChange');
    this.valueEl.dispatchEvent(event);
  }

  // prettier-ignore
  AddAlert() {
    const alert = document.createElement('li');
    alert.classList.add('custom-dropdown__item', 'custom-dropdown__item--alert');
    alert.setAttribute('aria-label', 'No matches were found. Please try again.');
    alert.innerText = 'No matches found. Please try again.';
    this.dropdownEl.append(alert);
  }

  RemoveAlert() {
    if (this.dropdownEl.querySelector('.custom-dropdown__item--alert')) {
      this.dropdownEl.querySelector('.custom-dropdown__item--alert').remove();
    }
  }
}

export default CustomDropdown;
