import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const TableOfContents = {
  // prettier-ignore
  init () {
    TableOfContents.root = document.querySelector('.tableofcontents');

    if (!TableOfContents.root) return;

    TableOfContents.hasInitiated = false;

    // All the TOC anchors
    TableOfContents.anchors = TableOfContents.root.querySelectorAll('.tableofcontents__anchor');
    // All the TOC headings
    TableOfContents.headings = document.querySelectorAll('.tableofcontents__heading');
    // Container of all page elements
    TableOfContents.elementalContainer = document.querySelector('#elemental-area');
    // All the page elements
    TableOfContents.elements = TableOfContents.elementalContainer.querySelectorAll('.element');
    // The first element we need to append the TOC before
    TableOfContents.entryElement = TableOfContents.elements[0].classList.contains(
      'app__elements__elementarticleheader',
    )
      ? TableOfContents.elements[1]
      : TableOfContents.elements[0];

    // Set the anchors so they're clickable
    TableOfContents.setAnchors();
    // Set the headings so they have IDs matching an anchor href
    TableOfContents.setHeadings();
    // Do some layout adjustments here
    // Most likely some FOUC will occur
    TableOfContents.adjustLayout();
    // Apply TOC offsets
    TableOfContents.setOffset();
    // Initiate the resize event that re-runs calcs
    TableOfContents.resizeEvent();
    // Initiate the intersection observer
    TableOfContents.scrollEvent();
  },
  adjustLayout () {
    // kill if we don't have permission (i.e too small) or this has already been executed
    if (!TableOfContents.getPermission() || TableOfContents.hasInitiated) {
      return;
    }

    // Rejig the element classes so they're left aligned
    TableOfContents.elements.forEach((element) => {
      switch (true) {
        case element.classList.contains(
          'tuapapa__tuapapapackage__elements__elementmedia',
        ):
          TableOfContents.adjustMediaElement(element);
          break;
        default:
          TableOfContents.adjustDefaultElement(element);
      }
    });

    // Move the TOC above the entry element
    TableOfContents.entryElement.insertAdjacentElement(
      'beforebegin',
      TableOfContents.root,
    );

    // Relative class on container to keep TOC within bounds
    TableOfContents.elementalContainer.classList.add('relative');

    TableOfContents.hasInitiated = true;
  },
  adjustDefaultElement (element) {
    const container = element.querySelector('.c-container');
    const grid = element.querySelector('.grid');

    // Only adjust elements with a grid
    if (!grid) return;

    const gridInner = grid.querySelector(':scope > div');

    // Remove existing classes and replace with new layout
    gridInner.removeAttribute('class');
    gridInner.classList.add(
      'col-span-4',
      'md:col-start-1',
      'lg:col-start-2',
      'md:col-span-6',
      'lg:col-span-7',
      'pr-10',
    );

    if (!container) return;

    // Give containers a slightly bigger scope
    container.classList.add('xs:max-w-px-1400');
    container.parentElement.classList.add('sm:px-4', 'md:px-10', 'lg:px-16');
  },
  adjustMediaElement (element) {
    const mediaElement = element.querySelector('.element-media');
    // container element
    const box = document.createElement('div');
    box.classList.add('c-container', 'xs:max-w-px-1400');
    // element with padding
    const padding = document.createElement('div');
    padding.classList.add('px-4', 'md:px-4', 'lg:px-0');
    // The grid
    const grid = document.createElement('div');
    grid.classList.add(
      'grid',
      'grid-cols-4',
      'md:grid-cols-8',
      'lg:grid-cols-12',
    );
    // The grid columns
    const cols = document.createElement('div');
    cols.classList.add(
      'col-span-4',
      'md:col-start-1',
      'lg:col-start-2',
      'md:col-span-6',
      'lg:col-span-7',
      'pr-10',
    );

    // Next each element
    box.appendChild(padding);
    padding.appendChild(grid);
    grid.appendChild(cols);
    mediaElement.appendChild(box);

    // Yet more classes we need to remove/add
    mediaElement.classList.remove('md:max-w-7xl', 'mx-auto', 'px-4', 'xl:px-0');
    mediaElement.classList.add('sm:px-4', 'md:px-10', 'lg:px-16');

    // Video type
    if (element.querySelector('.video-background')) {
      // The element containing the media
      const videoWrapper = element.querySelector('.video-background');

      // Nest video elements
      cols.appendChild(videoWrapper);
      mediaElement.appendChild(box);
    }
    // Image type
    else {
      // The element containing the media
      const imageWrapper = element.querySelector('.media-container');

      // Nest video elements
      cols.appendChild(imageWrapper);

      imageWrapper.classList.remove('media-offset');
    }
  },
  setOffset () {
    // Entry elements offset top
    const firstElementOffset = TableOfContents.entryElement.offsetTop;
    // Any padding the entry elements direct child has
    const secondElementPadding = parseInt(
      window.getComputedStyle(
        TableOfContents.entryElement.querySelector(':scope > div'),
      ).paddingTop,
      10,
    );
    const offset = firstElementOffset + secondElementPadding;
    const lastChildElement =      TableOfContents.elementalContainer.lastElementChild;
    let lastChildHeight = 0;
    if (
      lastChildElement.classList.contains(
        'app__elements__elementrelatedarticles'
      )
    ) {
      lastChildHeight = lastChildElement.clientHeight + 48;
    }

    console.log(lastChildHeight);
    // Align the TOC to the top of the second element
    TableOfContents.root.style.top = `${offset}px`;

    // Set the height of the TOC for sticky to work
    TableOfContents.root.style.height = `${
      TableOfContents.elementalContainer.offsetHeight - offset - lastChildHeight
    }px`;
  },
  setAnchors () {
    TableOfContents.anchors.forEach((anchor) => {
      const text = anchor.innerText;
      const anchorEl = document.createElement('a');
      const handle = TableOfContents.handleize(text);

      anchorEl.setAttribute('href', `#${handle}`);
      anchorEl.innerText = text;

      anchor.innerHTML = '';
      anchor.appendChild(anchorEl);

      TableOfContents.clickEvent(anchorEl, handle);
    });
  },
  setActiveLink () {
    const lastAnchor =      TableOfContents.anchors[TableOfContents.anchors.length - 1].querySelector(
        'a',
      );
    TableOfContents.anchors.forEach((anchor) => {
      anchor.querySelector('a')?.classList.remove('--active');
    });
    TableOfContents.activeLink?.classList.add('--active');
    if (TableOfContents.activeLink === lastAnchor) {
      lastAnchor.classList.add('fadeout');
    }
  },
  clickEvent (anchor, handle) {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      const target = document.getElementById(handle);

      gsap.to(window, {
        ease: 'power3.out',
        duration: 1,
        scrollTo: target.offsetTop - 50,
      });
    });
  },
  resizeEvent () {
    let to;

    window.addEventListener('resize', (event) => {
      clearTimeout(to);

      to = setTimeout(() => {
        TableOfContents.adjustLayout();
        TableOfContents.setOffset();
      }, 500);
    });
  },
  scrollEvent () {
    // Use intersection observer to efficiently update active link on scroll
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const el = entry.target;
            const link = TableOfContents.root.querySelector(
              `a[href="#${el.id}"]`,
            );
            TableOfContents.activeLink = link;
            TableOfContents.setActiveLink();
          }
        });
      },
      {
        rootMargin: '0px 0px -75%',
      },
    );

    TableOfContents.headings.forEach(el => observer.observe(el));
  },
  setHeadings () {
    TableOfContents.headings.forEach((heading) => {
      const text = heading.innerText;
      const handle = TableOfContents.handleize(text);
      heading.id = handle;
    });
  },
  handleize (text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  },
  getPermission () {
    return window.innerWidth >= 768;
  },
};

export default TableOfContents;
