const TealiumLinks = {
  init: () => {
    document.addEventListener('utagLoaded', TealiumLinks.setClickEvents);
  },
  setClickEvents: () => {
    const clickableElements = document.querySelectorAll('[data-tealium]');
    const elementsNotLabeled = document.querySelectorAll(
      '#elemental-area a:not([data-tealium])'
    );
    const elements = [...clickableElements, ...elementsNotLabeled];

    elements.forEach((element) => {
      element.addEventListener('click', TealiumLinks.triggerLinkEvent);
    });
  },
  triggerLinkEvent: (event) => {
    const data = TealiumLinks.getData(event);
    window.utag.link(data);
  },
  getData: (event) => {
    const element = event.currentTarget;
    const {
      tealiumEvent = 'link_click',
      tealiumLabel,
      tealiumCategory = 'content',
    } = element.dataset;

    const pageName = document.body.dataset.title;
    const label = TealiumLinks.formatLabel(tealiumLabel ?? element.textContent);

    return {
      tealium_event: tealiumEvent,
      page_name: pageName,
      link_label: label,
      link_category: tealiumCategory,
    };
  },
  formatLabel: (label) =>
    label
      .replace(/(<([^>]+)>)/gi, '') // Remove HTML tags
      .replace(/[\r\n]/gm, '') // Remove new lines
      .replace(/\s\s+/g, ' ') // Remove extra spacing
      .trim(),
};

export default TealiumLinks;
