import Swiper from 'swiper/bundle';

const mobile =
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const StoryCarousel = {
  initCarousel: () => {
    if (document.querySelector('.story-carousel')) {
      new Swiper('.story-carousel', {
        loop: true,
        autoplay: {
          delay: 3500,
        },
        speed: 950,
        spaceBetween: 0,
        slidesPerView: 1.33,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
      });
    }
  },
  mobileCarousel: () => {
    if (mobile > 768) {
      new Swiper('.story-carousel-mobile', {
        autoplay: {
          delay: 3500,
        },
        loop: true,
        speed: 950,
        spaceBetween: 0,
        slidesPerView: 1.33,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  },
};

window.addEventListener('resize', () => {
  StoryCarousel.mobileCarousel();
});

document.addEventListener('DOMContentLoaded', () => {
  if (mobile < 768) {
    new Swiper('.story-carousel-mobile', {
      autoplay: {
        delay: 3500,
      },
      loop: true,
      speed: 950,
      spaceBetween: 0,
      slidesPerView: 1.33,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
});

export default StoryCarousel;
