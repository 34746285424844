function MediaPlayer() {
  let YTPlayer = null;
  let milestoneInterval = null;
  const mileStones = [10, 25, 50, 75];
  const constants = {
    TEALIUM_LINK_EVENT_START: 'video_start',
    TEALIUM_LINK_EVENT_COMPLETE: 'video_complete',
    TEALIUM_LINK_EVENT_MILESTONE: 'video_milestone',
    TEALIUM_VIDEO_PLATFORM: 'Youtube',
    YOUTUBE_PLAYER_HOST: 'https://www.youtube-nocookie.com',
    DEBUG: false,
  };

  const tealiumVideoData = (additionalOptions) => {
    const {
      playerInfo: { videoData },
    } = YTPlayer;

    return {
      video_id: videoData.video_id,
      video_length: YTPlayer.getDuration(),
      video_name: videoData.title,
      video_platform: constants.TEALIUM_VIDEO_PLATFORM,
      ...additionalOptions,
    };
  };

  const sendData = (data) => {
    const tealiumData = tealiumVideoData(data);
    if (constants.DEBUG) {
      console.log(tealiumData);
    } else {
      window.utag.link(tealiumData);
    }
  };

  const setPlayEventOnButton = () => {
    const playButton = YTPlayer.getIframe().previousElementSibling;

    playButton?.addEventListener('click', () => {
      YTPlayer.playVideo();
      playButton.classList.add('hidden');
    });
  };

  function mileStoneCheck() {
    const duration = YTPlayer.getDuration();
    const currentTime = YTPlayer.getCurrentTime();
    const percentComplete = (currentTime / duration) * 100;
    const milestoneLength = mileStones.length;

    if (milestoneLength > 0) {
      const nextMileStone = mileStones[0];
      if (nextMileStone <= percentComplete) {
        mileStones.shift();
        sendData({
          tealium_event: constants.TEALIUM_LINK_EVENT_MILESTONE,
          video_playhead: Math.round(currentTime),
          video_milestone: nextMileStone,
        });
      }
    }
  }

  const onPlayerReady = (event) => {
    setPlayEventOnButton();
    event.target.setPlaybackQuality('hd720');
  };

  const onPlayerStateChange = (event) => {
    const { target: player, data: playerState } = event;
    const { playerInfo } = player;

    switch (playerState) {
      case window.YT.PlayerState.PLAYING: {
        if (Math.round(playerInfo.currentTime) === 0) {
          sendData({
            tealium_event: constants.TEALIUM_LINK_EVENT_START,
          });
        }

        milestoneInterval = setInterval(mileStoneCheck, 100);
        break;
      }
      case window.YT.PlayerState.PAUSED: {
        clearInterval(milestoneInterval);
        break;
      }
      case window.YT.PlayerState.ENDED: {
        clearInterval(milestoneInterval);
        sendData({
          tealium_event: constants.TEALIUM_LINK_EVENT_COMPLETE,
        });
        break;
      }
      case window.YT.PlayerState.BUFFERING: {
        event.target.setPlaybackQuality('hd720');
        break;
      }

      default:
        break;
    }
  };

  function getPlayerVars({ videoId, videoType }) {
    const playerVars = {
      version: 3,
      modestbranding: 1,
      rel: 0,
      autohide: 1,
      showinfo: 0,
    };

    const mediaOptions = {
      fs: 1,
    };

    const elementHeaderOptions = {
      fs: 0,
      loop: 1,
      controls: 0,
      muted: 1,
      mute: 1,
      background: 1,
      autoplay: 1,
      playlist: videoId,
    };

    if (videoType === 'media') {
      return { ...playerVars, ...mediaOptions };
    }

    if (videoType === 'element-header') {
      return { ...playerVars, ...elementHeaderOptions };
    }

    return playerVars;
  }

  return {
    init(element) {
      const { videoId, videoType } = element.dataset;
      const playerVars = getPlayerVars({ videoId, videoType });

      YTPlayer = new window.YT.Player(element, {
        height: '1080',
        width: '1920',
        videoId,
        playerVars,
        host: constants.YOUTUBE_PLAYER_HOST,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    },
  };
}

const Media = {
  init: () => {
    Media.setupVideos();
    Media.setupToggleTranscriptBlock();
  },
  setupVideos: () => {
    const tag = document.createElement('script');
    tag.id = 'iframe-api';
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => Media.setupPlayers();
  },
  setupPlayers: () => {
    const players = document.querySelectorAll('.youtube-player');
    players.forEach((player) => {
      const mediaPlayer = MediaPlayer();
      mediaPlayer.init(player);
    });
  },
  setupToggleTranscriptBlock: () => {
    const toggleBlocks = document.querySelectorAll('.toggle-block');
    toggleBlocks.forEach((item) => {
      const blockButton = item.querySelector('.toggle-block__button');
      blockButton.addEventListener('click', () => {
        item.querySelector('.toggle-block__content').classList.toggle('hidden');
        item.querySelector('.toggle-block__button').classList.toggle('open');
      });
    });
  },
};

export default Media;
