import gsap from 'gsap';
import imagesLoaded from '@components/utils/imagesLoaded';

class NaturalSearch {
  constructor(element) {
    this.root = element;
    // Form action for Fetch request
    this.action = this.root.getAttribute('action');
    // The submit button so we can disabled it
    this.button = this.root.querySelector('button');
    // The target element where results are displayed
    this.target = this.root.nextElementSibling;

    this.Events();
  }

  Events() {
    this.root.addEventListener('submit', async (e) => {
      e.preventDefault();

      // ID of the selected Tag
      const tagID = this.root.querySelector('.natural-search__item--tag .custom-dropdown__value')
        .dataset.value;
      // ID of the selected Category
      const categoryID = this.root.querySelector(
        '.natural-search__item--category .custom-dropdown__value'
      ).dataset.value;
      // Add them to the action
      const action = `${this.action}?ArticleTag=${tagID}&ArticleCategory=${categoryID}`;
      const options = { method: 'POST' };
      // Store results so we can run some differing logic
      let count = 0;

      // Disable the button
      this.button.disabled = true;

      this.root.removeAttribute('aria-invalid');
      this.root.removeAttribute('aria-errormessage');

      await gsap.to(this.target, {
        ease: 'power3.out',
        duration: 0.25,
        opacity: 0,
        y: 20,
        onComplete: () => {
          // Add classes to the wrapper that adds spacing and border
          if (!this.target.classList.contains('natural-search--results')) {
            this.target.classList.add('border-t', 'pt-6', 'natural-search--results');
          }
        },
      });

      // Run the fetch
      await fetch(action, options)
        .then((response) => {
          // Store the article count returned in header
          count = parseInt(response.headers.get('articleCount'), 10);
          return response.text();
        })
        .then((response) => {
          // If we have results, add the cards
          if (count) {
            this.target.innerHTML = response;
          }
          // No results means we need to display an error message
          else {
            this.root.setAttribute('aria-invalid', 'true');
            this.root.setAttribute('aria-errormessage', 'natural-search-error');
            this.target.innerHTML =
              '<p id="natural-search-error" class="m-auto">Sorry, there are no articles that match. Please try a different selection.</p>';
          }
        });

      // Wait until all images have loaded
      if (count) {
        await imagesLoaded(this.target.querySelectorAll('img'));
      }

      // Fade in the ontainer
      await gsap.to(this.target, {
        ease: 'power3.out',
        duration: 1,
        opacity: 1,
        y: 0,
        delay: 1,
        onBegin: () => {
          // re-enable the button
          this.button.disabled = false;
        },
      });
    });
  }
}

export default NaturalSearch;
