import gsap from 'gsap';
import imagesLoaded from '@components/utils/imagesLoaded';

class Pagination {
  constructor(element) {
    this.root = element;
    this.href = this.root.getAttribute('href');
    this.perPage = 12;
    this.start = this.perPage;

    this.target = document.querySelector('.pagination__target');

    this.Events();
  }

  Events() {
    this.root.addEventListener('click', async (e) => {
      e.preventDefault();

      if (this.root.classList.contains('pagination__link--loading')) {
        return false;
      }

      const action = `/news/articlePaginationResponse?start=${this.start}`;
      const options = { method: 'GET' };
      // Store results so we can run some differing logic
      let count = 0;

      // Disable the button
      this.root.classList.add('pagination__link--loading');

      // Run the fetch
      await fetch(action, options)
        .then((response) => {
          // Store the article count returned in header
          count = parseInt(response.headers.get('articleCount'), 10);
          return response.text();
        })
        .then((response) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(response, 'text/html');
          const articles = doc.querySelectorAll('body > div');

          articles.forEach((el) => {
            el.style.display = 'none';
            el.style.opacity = 0;
            el.style.transform = 'translateY(20px)';
            this.target.appendChild(el);
          });

          this.start += this.perPage;
        })
        .catch((err) => {
          console.warn('Something went wrong.', err);
        });

      // Wait until all images have loaded
      if (count) {
        await imagesLoaded(this.target.querySelectorAll('img'));

        if (count === 12) {
          this.root.classList.remove('pagination__link--loading');
        } else {
          this.root.remove();
        }

        // Fade in the ontainer
        await gsap.to(this.target.querySelectorAll('div[style]'), {
          display: 'block',
          ease: 'power3.out',
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          onComplete: () => {
            this.target.querySelectorAll('div[style]').forEach((el) => el.removeAttribute('style'));
          },
        });
      }

      return false;
    });
  }
}

export default Pagination;
