const Tabs = {
  init: () => {
    // tab set 1 click event
    document.querySelector('.tablinks')
      ? document.querySelectorAll('.tablinks').forEach((tabs) => {
          Tabs.tabLinks(tabs, '.tablinks', '.tabcontent');
        })
      : null;

    // additional tab set on the same page
    document.querySelector('.tablinks2')
      ? document.querySelectorAll('.tablinks2').forEach((tabs) => {
          Tabs.tabLinks(tabs, '.tablinks2', '.tabcontent2');
        })
      : null;

    // tabs scroll for mobile
    document.getElementById('left-scroll')
      ? document.getElementById('left-scroll').addEventListener('click', () => {
          document.getElementById('scrolling-tabs').scrollBy('-300', 'smooth');
        })
      : null;

    document.getElementById('right-scroll')
      ? document
          .getElementById('right-scroll')
          .addEventListener('click', () => {
            document.getElementById('scrolling-tabs').scrollBy('300', 'smooth');
          })
      : null;

    /* handle arrow indication when scroll */
    const tabContainer = document.querySelector('.tabs-container');
    const tabItems = document.querySelector('.tab-items');
    const tabArrow = document.querySelector('.tab-overflow-arrow');

    if (tabItems.clientWidth > tabContainer.clientWidth) {
      // set arrow div height to match the container
      tabArrow.style.height = `${tabItems.clientHeight.toString()}px`;

      const handleArrowDisplay = () => {
        if (tabItems.clientWidth > tabContainer.clientWidth) {
          if (tabArrow.classList.contains('opacity-0')) {
            tabArrow.classList.remove('opacity-0');
          }
        } else {
          tabArrow.classList.add('opacity-0');
        }
      };
      /* init  */
      handleArrowDisplay();

      /* handle arrow display when resized */
      window.addEventListener('resize', () => {
        handleArrowDisplay();
      });

      const boxes = document.querySelectorAll('.tabs-container');
      boxes.forEach((box) => {
        box.addEventListener('scroll', () => {
          // const offset = 2 // excess value, mab be the border....
          const windowScrollWidth = box.scrollWidth;
          const userScroll = box.scrollLeft;
          const calculation = windowScrollWidth - tabContainer.clientWidth;

          /* hide the right arrow when reach the end of right  */
          if (userScroll >= calculation) {
            tabArrow.classList.add('opacity-0');
          } else if (tabArrow.classList.contains('opacity-0')) {
            tabArrow.classList.remove('opacity-0');
          }
        });
      });
    }
  },

  /**
   * @param links
   * @param content
   */
  tabLinks: (tabs, linkClass, contentClass) => {
    tabs.addEventListener('click', (tab) => {
      tab.preventDefault();
      // hide all other tabcontent
      document.querySelectorAll(contentClass).forEach((tabcontent) => {
        tabcontent.classList.add('hidden');
      });

      // hide all other active tab links
      document.querySelectorAll(linkClass).forEach((links) => {
        links.classList.remove('active');
      });

      // add show to the correct tab and active link
      document.getElementById(tabs.dataset.tab).classList.remove('hidden');
      tabs.classList.add('active');
    });
  },
};

export default Tabs;
